<template>
    <b-modal :ref="uniqueModalRef">
        <template slot="modal-header" slot-scope="{ close }">
            <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
            <h2 class="mx-auto my-auto">{{ $t('sanitaire.demande_analyse') }}</h2>
        </template>
        <template slot="default">
            <div class="col-12" v-if="canSubmit">
                <ErrorAlert :messageI18n="form_message" />
            </div>
            <template v-else>
            	<div class="form-group">
                	<label for="demandeur" class="col-form-label">{{ $t("sanitaire.tier_demandeur") }}<span> *</span></label>
    	        	<e-select
    	                v-model="demandeurSelected"
    	                id="demandeur"
    	                track-by="tiers_rs"
    	                label="tiers_rs"
    	                class="primary"
    	                :options="entities"
    	                :searchable="true"
    	                :show-labels="false"
    	                :allow-empty="false"
    	            >
    	            	<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }}</template>
    	                <template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
    	                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
    	            </e-select>
    	        </div>
    	        <div v-for="horse in horses" class="form-group" :key="horse.horse_id">
                	<label for="facture" class="col-form-label">{{ $t("sanitaire.tier_facture") }} - {{ horse.horse_nom }}<span> *</span></label>
    	        	<e-select
    	                v-model="horse.facture_tiers"
    	                id="facture"
    	                track-by="tiers_rs"
    	                label="tiers_rs"
    	                class="primary"
    	                :options="entities_facture"
    	                :searchable="true"
    	                :show-labels="false"
    	                :allow-empty="false"
    	            >
    	            	<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }}</template>
    	                <template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
    	                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
    	            </e-select>
    	        </div>
            </template>
        </template>
        <template slot="modal-footer" slot-scope="{ ok, cancel}" class="justify-content-center">
            <b-button v-if="!canSubmit" variant="primary" @click="handleSubmit">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse class="mr-1"/>
                <font-awesome-icon v-else :icon="['fal', 'file-medical']" class="mr-1"/>{{ $t('global.valider') }}
            </b-button>
            <b-button @click="cancel()">
                <font-awesome-icon :icon="['fal', 'times']" class="mr-1"/> {{ $t('global.annuler') }}
            </b-button>
        </template>
    </b-modal>
</template>


<script type="text/javascript">
    import Actes from "@/mixins/Actes.js"
    import Tiers from "@/mixins/Tiers.js"
    import ResultatAnalyse from "@/mixins/ResultatAnalyse.js"
    import Common from '@/assets/js/common'
    import _uniqBy from 'lodash/uniqBy'
    import _mapValues from 'lodash/mapValues'

	export default {
        props: {
	        callback_validation : Function
		},
		mixins: [Actes, Tiers, ResultatAnalyse],
        data() {
            return {
            	actes: [],
            	demandeurSelected: null,
            	entities: [],
                entities_facture: [],
                form_message: "",
                processing: false,
                horses: []
            }
        },
        methods: {
            openModal(actes) {
            	this.actes = actes
                this.form_message = ''
				this.$refs[this.uniqueModalRef].show()
                this.checkActe()
                this.setHorses()
            },

            async checkActe() {
                const sanitaire = [
                    "TEST_NEW2_ANMIE_INFCTIEUSE_DES_EQUIDS_AIE",
                    "TEST_NEW2_ARTRITE_VIRALE_QUINE_EVA_POUR_JUMENT",
                    "TEST_NEW2_ARTRITE_VIRALE_QUINE_EVA_POUR_TALON",
                    "TEST_NEW2_MTRITE_CONTAGIEUSE_QUINE_MEC_POUR_TALON",
                    "TEST_NEW2_MTRITE_CONTAGIEUSE_QUINE_MEC_POUR_JUMENT"
                ]
                const check = this.actes.every(act => sanitaire.includes(act.actetype.actestype_code))
                if(!check) {
                    this.form_message = 'sanitaire.not_only_sanitaire'
                    return false
                }
                this.form_message = ''
                this.loadEntity()
            },

            setHorses() {
                const horses = this.actes.map(acte => {
                   return { 
                        horse_id: acte.actes_horse,
                        horse_nom: acte.horse_nom,
                        facture_tiers: null
                    }
                })

                this.horses = _uniqBy(horses, 'horse_id')
            },

            async loadEntity() {
				this.entities = await this.loadTiersEntity()
                this.entities_facture = [{tiers_rs: this.getTrad('sanitaire.tiers_proprio'), tiers_id: -1}, ... this.entities]
                this.demandeurSelected = this.entities[0]
            },

            closeModal() {
				this.$refs[this.uniqueModalRef].hide()
            },

            async handleSubmit() {
                this.processing = true

                if(!this.demandeurSelected.tiers_id) {
                    this.processing = false
                    return false
                }

                let promises = []
                await Common.asyncForEach(this.horses, async (horse) => {
                    if(!horse.facture_tiers) {
                        this.processing = false
                        return false
                    }
                    const actes_horse = this.actes.filter(act => act.actes_horse == horse.horse_id)
                    const actes_ids = actes_horse.map(act => act.actes_id)

                    let actes_labo = {}

                    await Common.asyncForEach(actes_ids, async (acte) => {
                        const rep = await this.getActeWithReponse(acte)
                        const rep_labo = rep.reponse.find(reponse => reponse.question.question_code == "Laboratoire")

                        if(!Object.prototype.hasOwnProperty.call(actes_labo, rep_labo.reponse_formatted_data)) {
                            actes_labo[rep_labo.reponse_formatted_data] = []
                        }
                        actes_labo[rep_labo.reponse_formatted_data].push(rep.actes_id)
                    })

                    for(let labo in actes_labo) {
                        promises.push(this.createAnalyse(actes_labo[labo], this.demandeurSelected.tiers_id, horse.facture_tiers.tiers_id, horse.horse_nom))
                    }
                })

                await Promise.all(promises)
                this.processing = false
				this.closeModal()
            }
        },
        computed: {
            uniqueModalRef() {
                return 'modal-demande-analyse-'+this._uid
            },
            canSubmit() {
                return this.form_message != ''
            }
        },
        watch: {
        	demandeurSelected(val) {
                this.horses.forEach((horse, idx) => {
                    if(!horse.facture_tiers) {
                        this.horses[idx].facture_tiers = val
                    }
                })
        	}
        },
        components: {
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
        }
	}
</script>
